import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const useLbCountdown = () => {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        // Get the system's current timezone
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Function to calculate time left until the end of the week
        const calculateTimeLeft = () => {
            const now = moment.tz(new Date(), systemTimezone);
            const endOfWeek = now.clone().endOf('week');

            const durationInMilliseconds = endOfWeek.diff(now);
            const duration = moment.duration(durationInMilliseconds);

            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Update the state with hours, minutes, and seconds left
            setTimeLeft({ hours, minutes, seconds });
        };

        // Calculate time left immediately when the component mounts
        calculateTimeLeft();

        // Update every second
        const timer = setInterval(calculateTimeLeft, 1000);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(timer);
    }, []);

    return timeLeft;
};

export default useLbCountdown;
