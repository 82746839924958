// export const LOGIN_USER = "/users/login";
export const LOGIN_USER = "/users/loginWithToken";
export const REGISTER_USER = "/users/register";
export const VERIFY_OTP = "/users/verifyOtp";
export const FORGOT_PASSWORD = "/users/forgotPassword";
export const CHANGE_PASSWORD = "/users/changePassword";
export const USER_SESSION = "/users/session";
export const USER_DETAILS = "/users/profile";
//Settings
export const GET_SETTINGS = "/settings/view";

export const GAME_START = "/games/start";
export const GAME_DETAILS = "/games/details/";
export const GAME_COMPLETE = "/games/complete/";
export const GAME_UPDATE = "/games/update/";
export const GAME_HISTORY_LIST = "/games/history-list";
export const GAME_LEADERBOARD_LIST = "/leaderboard/list"
export const GET_CSRF_TOKEN = "/users/token";

export const USER_LOGOUT = "/users/logout";
export const PURCHASE_ENERGY = "/energy/purchase";
export const UPDATE_PROFILE = "/users/edit/";

//task
export const TASK_LIST = "/tasks/user/list";
export const TASK_COMPLETE = "/tasks/complete/";

//claim
export const CLAIM_LIST = "/claim/list";
export const CLAIM_BALANCE = "/claim/transfer/";
export const GET_BTANICA_BALANCE = "/bitanica/btnc/balance";
export const TRANSFER_BTNC_FROM_BITANICA = "/bitanica/transfer/btnc";

//roadmaps
export const GET_ROADMAPS_LIST = "/roadmaps/list";
export const CHECK_BITANICA_LINKING = "/users/check/linking";

export const USER_BOT_LOGIN = "/users/login/bot";
export const GENERATE_TEL_LOGIN_QR = "/users/generate/qrcode";

