/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get } from "../../../helper/api_helper";

export const getSettings = createAsyncThunk(
  "getSettings",
  async ({ data, callBack }, Thunk) => {
    console.log("settings", data)
    try {
      const response = await get(URL.GET_SETTINGS);
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



