import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import styles from "./BtncPop.module.scss";
import diamond from "../../../assets/images/diamond.png";
import { useDispatch, useSelector } from "react-redux";
import { getBitanicaBtncBalanceApi, transferFromBitanicaApi } from "../../../lib/store/actions";
import { showToast } from "../../../helper/common";

const BtncBalancePop = ({ pop, setPop, onFinish }) => {
  const [amount, setAmount] = useState(1);
  const { user } = useSelector(({ Auth }) => Auth);
  const { userBitanicaBtncBalance, binaticaLoading, btncAddLoading } = useSelector(({ Game }) => Game);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch((getBitanicaBtncBalanceApi({})));
  }, [])

  const transferBtn = () => {
    if (!amount) return;
    if (amount > userBitanicaBtncBalance) {
      return showToast("You cannot transfer more than your bitanica balance!", "error");
    }
    let bodyParm = {
      amount: amount,
    };
    // setIsLoading(true);
    dispatch(
      transferFromBitanicaApi({
        data: bodyParm,
        callBack: (err, res) => {
          if (err) return;
          onFinish(res?.data);
          dispatch((getBitanicaBtncBalanceApi({})));
        },
      })
    );
  };
  const handledHide = () => {
    setPop(!pop);
  };
  return (
    <>
      <Modal
        show={pop}
        onHide={handledHide}
        backdrop="true"
        centered
        scrollable="true"
        className={`${styles.buyPop} bottomPop`}
        keyboard={false}
      >
        <div className="modalWrpper">

          <Modal.Body>
            <Button
              onClick={handledHide}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            <div className="top pb-3 text-center">
              <h5
                className="m-0 text-white d-inline-block pb-1 fw-bold"
                style={{ borderBottom: "1px solid #02ADEA" }}
              >
                BITANICA BTNC
              </h5>
            </div>
            <div className="py-2">
              <div
                className={`${styles.box} shadow-sm position-relative mx-auto text-center rounded-4 p-3`}
                style={{ maxWidth: 290 }}
              >
                <div className="py-1">
                  <img
                    src={diamond}
                    alt=""
                    className="img-fluid"
                  />              </div>
                <div
                  className={`${styles.quantity} pt-2 pb-3 d-flex align-items-center justify-content-center gap-10`}
                >
                  {/* <Button
                  onClick={() => handleQty("min")}
                  className={`${qty > 1 && styles.active
                    } d-flex align-items-center justify-content-center commonBtn border-0`}
                  style={{ minWidth: "unset" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="3"
                    viewBox="0 0 15 3"
                    fill="none"
                  >
                    <rect width="15" height="3" rx="1.5" fill="white" />
                  </svg>
                </Button> */}
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className={`${styles.formControl} form-control text-center fw-bold text-white`}
                  />
                  {/* <Button
                  onClick={() => handleQty("add")}
                  className={`${qty < 15 && styles.active
                    } d-flex align-items-center justify-content-center commonBtn border-0`}
                  style={{ minWidth: "unset" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <rect
                      y="6.31738"
                      width="16.1092"
                      height="3.15924"
                      rx="1.57962"
                      fill="white"
                    />
                    <rect
                      width="15.7938"
                      height="3.22185"
                      rx="1.61092"
                      transform="matrix(0.00512754 -0.999987 0.999988 0.0049302 6.44098 15.7939)"
                      fill="white"
                    />
                  </svg>
                </Button> */}
                </div>
                <div
                  className="mt-2 d-inline-flex align-items-center px-3 justify-content-center  text-white fw-bold"
                  style={{ minWidth: "unset", boxShadow: "unset" }}
                >
                  <span className="icn me-2">
                    <img
                      src={diamond}
                      alt=""
                      className="img-fluid"
                      style={{ height: 40 }}
                    />
                  </span>
                  {userBitanicaBtncBalance || 0}
                </div>
              </div>
            </div>
            <div className="mt-3 text-center">
              <Button
                disabled={binaticaLoading || btncAddLoading}
                style={{ maxWidth: 290 }}
                onClick={() => transferBtn()}
                className="d-inline-flex align-items-center justify-content-center fw-bold commonBtn border-0"
              >
                {btncAddLoading ? <Spinner size="sm" /> : ""}{"ADD"}
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default BtncBalancePop;
