import React, { useState } from "react";
import { Button, Form, Modal, Nav, Tab } from "react-bootstrap";
import styles from "./LinkAccountPop.module.scss";

// img
import diamond from "../../../assets/images/diamond.png";
import { useSelector } from "react-redux";
import { copyToClipboard, showToast } from "../../../helper/common";

const LinkAccountPop = ({ linkAccount, setLinkAccount, rowData }) => {
  const { user } = useSelector(({ Auth }) => Auth);
  const handleLinkAccount = () => {
    setLinkAccount(!linkAccount);
  };

  const onCopy = async (value) => {
    try {
      const message = await copyToClipboard(value);
      // addToast(message, 'success', 3000);
      showToast(message, "success")
    } catch (err) {
      // addToast(err, 'error', 3000);
      showToast(typeof err === "string" ? err : err?.message, "error")
    }

  }
  return (
    <>
      <Modal
        show={linkAccount}
        onHide={handleLinkAccount}
        backdrop="true"
        centered
        className={`${styles.LinkAccountPop} bottomPop`}
        keyboard={false}
      >
        <div className="modalWrpper">
          <Modal.Body className="pb-4">
            <Button
              onClick={handleLinkAccount}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            <div className="py-2 text-center">
              <img src={rowData?.coinIcon || diamond} alt="" style={{ height: 100 }} className="img-fluid object-fit-contain" />
              <h4 className="m-0 fw-bold text-white py-2">
                Connection to <br /> <span className="themeClr">Bitanica.com</span>{" "}
                exchange
              </h4>
              <div className="py-2 mt-2">
                <Form className="mx-auto" style={{ maxWidth: 313 }}>
                  <p className="m-0 pb-1">
                    Use this code to link your account to Bitanica
                  </p>
                  <div
                    className="input d-flex align-items-center justify-content-between my-1 mb-2 p-2 rounded-2"
                    style={{ background: "#1B1B26", height: 45 }}
                  >
                    <p className="m-0">{user?.username}</p>
                    <Button
                      onClick={() => onCopy(user?.username)}
                      className="border-0 p-0"
                      variant="transparent"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="22"
                        viewBox="0 0 19 22"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M14 22H2C0.895 22 0 21.105 0 20V6H2V20H14V22ZM17 18H6C4.895 18 4 17.105 4 16V2C4 0.894999 4.895 -4.76837e-07 6 -4.76837e-07H17C18.105 -4.76837e-07 19 0.894999 19 2V16C19 17.105 18.105 18 17 18ZM17 2H6V16H17V2Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                  </div>
                  <Button
                    style={{ height: 50 }}
                    onClick={() => window.open("https://bitanica.com/", "blank")}
                    className={`${styles.submit} d-inline-flex align-items-center w-100 fw-bold justify-content-center commonBtn border-0 my-1`}
                  >
                    Link to Bitanica
                  </Button>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default LinkAccountPop;
