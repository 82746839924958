"use client";
/* Core */
import { createSlice } from "@reduxjs/toolkit";
import { checkBitanicaLinkApi, generateTelLoginQrApi, getUserProfile, telegramBotAutoLogin, telegramLogin, updateProfile, userSession, userSessionLogout } from "./actions";

/* Instruments */
// import { login } from "./actions";


const initialState = {
  user: null,
  loading: false,
  register: null,
  requestOTPLoading: false,
  updateLoader: false,
  logoutLoading: false,
  loadingBitanicLink: false,
  isBitanicaLinked: false,
  generateQrLoading: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserDetails(state, action) {
      const { user } = action.payload;
      state.user = user;
    },
    clearUserDetails(state) {
      state.user = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(telegramLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(telegramLogin.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = false;
      })
      .addCase(telegramLogin.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(userSession.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userSession.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = false;

      })
      .addCase(userSession.rejected, (state, action) => {
        state.user = null;
        state.loading = false;

      })
      .addCase(getUserProfile.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.user = action.payload.data;
        // state.loading = false;

      })
      .addCase(getUserProfile.rejected, (state, action) => {
        // state.loading = false;

      })
      .addCase(updateProfile.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload.data;
        // state.loading = false;

      })
      .addCase(updateProfile.rejected, (state, action) => {
        // state.loading = false;

      })
      .addCase(userSessionLogout.pending, (state, action) => {
        state.logoutLoading = true;
      })
      .addCase(userSessionLogout.fulfilled, (state, action) => {
        state.logoutLoading = false;
        state.user = null;

      })
      .addCase(userSessionLogout.rejected, (state, action) => {
        state.logoutLoading = false;

      })
      .addCase(checkBitanicaLinkApi.pending, (state, action) => {
        state.loadingBitanicLink = true;
        state.isBitanicaLinked = false;
      })
      .addCase(checkBitanicaLinkApi.fulfilled, (state, action) => {
        state.loadingBitanicLink = false;
        state.isBitanicaLinked = action.payload?.data?.is_linked;
      })
      .addCase(checkBitanicaLinkApi.rejected, (state, action) => {
        state.loadingBitanicLink = false;
        state.isBitanicaLinked = false;
      })
      .addCase(telegramBotAutoLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(telegramBotAutoLogin.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = false;
      })
      .addCase(telegramBotAutoLogin.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(generateTelLoginQrApi.pending, (state, action) => {
        state.generateQrLoading = true;
      })
      .addCase(generateTelLoginQrApi.fulfilled, (state, action) => {
        state.generateQrLoading = false;
      })
      .addCase(generateTelLoginQrApi.rejected, (state, action) => {
        state.generateQrLoading = false;
      })
  },
});

export default authSlice.reducer;