import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  Spinner,
} from "react-bootstrap";
import styles from "./gameHistory.module.scss";
// img
import p1 from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";
import GameHistoryDetailPop from "../../../components/Modals/GameHistoryDetailPop";
import { useDispatch, useSelector } from "react-redux";
import { gameHistory } from "../../../lib/store/actions";
import { copyToClipboard, showToast, wait } from "../../../helper/common";
import moment from "moment";
import { decrypt } from "../../../helper/secretManager";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderCstm from "../../../components/Loader";
import { gameSlice } from "../../../lib/store/game/slice";
import { useNavigate } from "react-router-dom";

const GameHistory = () => {
  const navigate = useNavigate();
  const [historyPop, setHistoryPop] = useState(false);

  const dispatch = useDispatch();
  const { user, gameHistoryList, totalGameHistory, loading } = useSelector(
    ({ Auth, Game }) => ({
      user: Auth.user,
      loading: Game.loading,
      gameHistoryList: Game.gameHistoryList,
      totalGameHistory: Game.totalGameHistory,
    })
  );
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    status: "",
  });

  useEffect(() => {
    dispatch(gameHistory({ data: filter, userId: user?._id }));
  }, [filter, user]);

  const fetchMore = async () => {
    console.log("fetch more");
    await wait(1000);
    setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const onClosePop = () => {
    console.log("onCLose history Pop");
    setHistoryPop(false);
  };

  const onOpenHistoryModal = (row) => {
    setHistoryPop(true);
    dispatch(gameSlice.actions.setGameHistoryDetails({ data: row }));
  };

  const onCopy = async (value) => {
    try {
      const message = await copyToClipboard(value);
      // addToast(message, 'success', 3000);
      showToast(message, "success")
    } catch (err) {
      // addToast(err, 'error', 3000);
      showToast(typeof err === "string" ? err : err?.message, "error")
    }

  }

  return (
    <>
      <section className={`${styles.gameHistory} gameHistory`}>
        {historyPop && (
          <GameHistoryDetailPop show={historyPop} onHide={onClosePop} />
        )}

        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className={`${styles.cardCstm} py-2 cardCstm`}>
                <div className="top pb-3 text-center position-relative">
                  <Button
                    onClick={() => navigate(-1)}
                    className="border-0 p-0 position-absolute "
                    variant="transparent"
                    style={{ top: 0, left: 10 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 20"
                      fill="none"
                    >
                      <path
                        d="M2.91436 9.76907L12.1877 2.01034C12.4158 1.81953 12.4158 1.5135 12.1877 1.32268C11.9596 1.13186 11.5939 1.13186 11.3658 1.32268L1.67935 9.42704C1.45128 9.61785 1.45128 9.92388 1.67935 10.1147L11.3658 18.2155C11.4777 18.3091 11.6283 18.3595 11.7746 18.3595C11.9209 18.3595 12.0715 18.3127 12.1834 18.2155C12.4115 18.0246 12.4115 17.7186 12.1834 17.5278L2.91436 9.76907Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2.26051"
                      />
                    </svg>
                  </Button>
                  <p
                    className="m-0 fw-bold d-inline-block pb-1"
                    style={{
                      borderBottom: "1px solid #02ADEA",
                      color: "#54B3F5",
                    }}
                  >
                    Game History
                  </p>
                </div>
                {loading && filter.page === 1 && <LoaderCstm />}
                <InfiniteScroll
                  dataLength={gameHistoryList?.length}
                  next={fetchMore}
                  hasMore={gameHistoryList?.length < totalGameHistory}
                  scrollThreshold={0}
                  // scrollableTarget="discover"
                  loader={
                    loading &&
                    filter.page >= 2 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <Spinner className={styles.mSpinner} size="sm" />
                      </div>
                    )
                  }
                >
                  <div className="table-responsive">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th className="border-0 bg-transparent fw-normal">
                            Round ID
                          </th>
                          <th className="border-0 bg-transparent fw-normal">
                            Line
                          </th>
                          <th className="border-0 bg-transparent fw-normal text-left">
                            Claim
                          </th>
                          <th className="border-0 bg-transparent fw-normal">
                            Date
                          </th>
                          <th className="border-0 bg-transparent fw-normal"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {gameHistoryList?.map((item, index) => (
                          <tr key={"history" + index}>
                            <td className="border-0 bg-transparent px-2 py-3">
                              <h6 className="cursor-pointer m-0 fw-normal text-white">
                                {item?.roundId}{" "}
                                <span
                                  className="icn ms-1 cursor-pointer"
                                  onClick={() => onCopy(item?.roundId)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.70654 5.71669C9.70654 4.21229 9.70654 3.4601 9.22985 2.99275C8.75316 2.52539 7.98593 2.52539 6.45148 2.52539H4.82395C3.28952 2.52539 2.5223 2.52539 2.0456 2.99275C1.56889 3.4601 1.56889 4.21229 1.56889 5.71669V8.3761C1.56889 9.88048 1.56889 10.6327 2.0456 11.1C2.5223 11.5674 3.28952 11.5674 4.82395 11.5674H6.45148C7.98593 11.5674 8.75316 11.5674 9.22985 11.1C9.70654 10.6327 9.70654 9.88048 9.70654 8.3761V5.71669Z"
                                      stroke="#54B3F5"
                                      stroke-width="1.17547"
                                    />
                                    <path
                                      d="M9.88809 9.75905C10.787 9.75905 11.5156 9.04468 11.5156 8.1634V4.9721C11.5156 2.96625 11.5156 1.96332 10.88 1.34018C10.2444 0.717041 9.22148 0.717041 7.17554 0.717041H5.0055C4.10662 0.717041 3.37797 1.43144 3.37797 2.31269"
                                      stroke="#54B3F5"
                                      stroke-width="1.17547"
                                    />
                                  </svg>
                                </span>
                              </h6>
                            </td>
                            <td className="border-0 bg-transparent px-2 py-3">
                              <h6 className="m-0 fw-normal text-white">
                                {item?.lineCount}
                              </h6>
                            </td>
                            <td className="border-0 bg-transparent px-2 py-3 text-left">
                              {item?.winnings?.length ? (
                                item?.winnings?.length === 1 ? (
                                  <div className="d-flex align-items-center">
                                    <span className="icn me-1">
                                      <img
                                        src={item?.winnings?.[0]?.icon}
                                        alt=""
                                        style={{ height: 14, width: 14 }}
                                        className=" img-fluid object-fit-contain"
                                      />
                                    </span>
                                    {item?.winnings?.[0]?.amount || 0}
                                  </div>
                                ) : (
                                  <Dropdown className={`${styles.dropdown}`}>
                                    <Dropdown.Toggle
                                      variant="transparent"
                                      id="dropdown-basic"
                                      className="rounded-2 d-flex align-items-center justify-content-center px-1 border-0"
                                    >
                                      <span className="icn me-1"></span>
                                      See all...
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                      className={`${styles.DropdownMenu} p-0 pb-2`}
                                    >
                                      <span
                                        className={`${styles.dropIcn} test dropIcn position-absolute`}
                                        style={{
                                          left: 16,
                                          top: 2,
                                          transform: "translateY(-100%) ",
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="38"
                                          height="16"
                                          viewBox="0 0 38 16"
                                          fill="none"
                                        >
                                          <g clip-path="url(#clip0_2_2513)">
                                            <g filter="url(#filter0_d_2_2513)">
                                              <path
                                                d="M37.9995 17.85L37.8602 17.8498L37.7591 17.7539L21.6965 2.51506C19.9039 0.814331 17.0941 0.812744 15.2995 2.51146L0.740608 16.2926L0.639381 16.3885H0.5H-98.6995C-101.854 16.3932 -104.888 17.794 -107.132 20.299C-109.377 22.8052 -110.646 26.2104 -110.65 29.7695V148.769C-110.646 152.328 -109.377 155.733 -107.132 158.239C-104.888 160.744 -101.855 162.145 -98.6997 162.15H40.6997C43.8546 162.145 46.888 160.744 49.1319 158.239C51.377 155.733 52.6458 152.328 52.65 148.769V32.5004C52.6458 28.9587 50.9965 25.2533 48.7393 22.7335C47.6317 21.4971 45.7454 20.2729 43.7141 19.3556C41.6819 18.4378 39.5601 17.8523 37.9995 17.85Z"
                                                fill="url(#paint0_linear_2_2513)"
                                                stroke="#078CF3"
                                                stroke-width="0.7"
                                              />
                                            </g>
                                          </g>
                                          <defs>
                                            <filter
                                              id="filter0_d_2_2513"
                                              x="-119"
                                              y="0.888428"
                                              width="180"
                                              height="177.612"
                                              filterUnits="userSpaceOnUse"
                                              color-interpolation-filters="sRGB"
                                            >
                                              <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                              />
                                              <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                              />
                                              <feOffset dy="8" />
                                              <feGaussianBlur stdDeviation="4" />
                                              <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                              />
                                              <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                              />
                                              <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_2_2513"
                                              />
                                              <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_2_2513"
                                                result="shape"
                                              />
                                            </filter>
                                            <linearGradient
                                              id="paint0_linear_2_2513"
                                              x1="-111"
                                              y1="362.5"
                                              x2="23"
                                              y2="-73.5"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stop-color="#00EBFB" />
                                              <stop
                                                offset="1"
                                                stop-color="#02659E"
                                              />
                                            </linearGradient>
                                            <clipPath id="clip0_2_2513">
                                              <rect
                                                width="38"
                                                height="16"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </span>
                                      {/* <Button
                                                                            className="border-0 px-2 py-1"
                                                                            variant="transparent"
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="9"
                                                                                height="12"
                                                                                viewBox="0 0 9 12"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M1.9071 5.99974L7.88965 1.48358C8.03678 1.3725 8.03678 1.19437 7.88965 1.0833C7.74251 0.972232 7.50654 0.972232 7.35941 1.0833L1.11035 5.80065C0.963216 5.91172 0.963216 6.08985 1.11035 6.20092L7.35941 10.9162C7.43159 10.9707 7.52875 11 7.62314 11C7.71753 11 7.81469 10.9728 7.88687 10.9162C8.03401 10.8051 8.03401 10.627 7.88687 10.5159L1.9071 5.99974Z"
                                                                                    fill="white"
                                                                                    stroke="white"
                                                                                    stroke-width="1.5"
                                                                                />
                                                                            </svg>
                                                                        </Button> */}
                                      <ol className="list-unstyled ps-0 mb-0">
                                        {item?.winnings?.map((win, key) => (
                                          <li
                                            key={key}
                                            className="d-flex align-items-center px-2"
                                          >
                                            <span className="numb text-white fw-bold">
                                              {key + 1}.
                                            </span>
                                            <Button
                                              className="d-flex align-items-center text-white py-1"
                                              variant="transparent"
                                            >
                                              <span className="icn me-1">
                                                <img
                                                  src={win?.icon}
                                                  alt=""
                                                  style={{
                                                    height: 14,
                                                    width: 14,
                                                  }}
                                                  className="img-fluid object-fit-contain"
                                                />
                                              </span>
                                              {win?.amount || 0}
                                            </Button>
                                          </li>
                                        ))}
                                      </ol>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )
                              ) : (
                                "--"
                              )}
                            </td>
                            <td className="border-0 bg-transparent px-2 py-3">
                              {item?.completedAt &&
                                moment(item?.completedAt).format(
                                  "DD.MM.YY HH:mm:ss"
                                )}
                            </td>
                            <td className="border-0 bg-transparent px-2 py-3">
                              <Button
                                onClick={() => onOpenHistoryModal(item)}
                                variant="transparent"
                                className="border-0 p-0"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="10"
                                  viewBox="0 0 19 10"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.50784 7.23569C3.1735 6.41775 2.22044 5.48287 1.77333 5C2.22044 4.51713 3.1735 3.58224 4.50784 2.76434C5.88545 1.91991 7.58648 1.25 9.49997 1.25C11.4135 1.25 13.1146 1.91991 14.4922 2.76434C15.8266 3.58224 16.7796 4.51713 17.2267 5C16.7796 5.48287 15.8266 6.41775 14.4922 7.23569C13.1146 8.08012 11.4135 8.75 9.49997 8.75C7.58648 8.75 5.88545 8.08012 4.50784 7.23569ZM9.49997 0C7.00111 0 4.90705 0.871638 3.36605 1.81622C1.81946 2.76423 0.746216 3.836 0.269914 4.35612C-0.0899714 4.74906 -0.0899714 5.25094 0.269914 5.64388C0.746216 6.164 1.81946 7.23575 3.36605 8.18381C4.90705 9.12838 7.00111 10 9.49997 10C11.9989 10 14.093 9.12838 15.634 8.18381C17.1806 7.23575 18.2538 6.164 18.7301 5.64388C19.09 5.25094 19.09 4.74906 18.7301 4.35612C18.2538 3.836 17.1806 2.76423 15.634 1.81622C14.093 0.871638 11.9989 0 9.49997 0ZM7.74789 5C7.74789 4.30963 8.53239 3.75 9.49997 3.75C10.4676 3.75 11.2521 4.30963 11.2521 5C11.2521 5.69037 10.4676 6.25 9.49997 6.25C8.53239 6.25 7.74789 5.69037 7.74789 5ZM9.49997 2.5C7.56472 2.5 5.99585 3.61929 5.99585 5C5.99585 6.38069 7.56472 7.5 9.49997 7.5C11.4353 7.5 13.0041 6.38069 13.0041 5C13.0041 3.61929 11.4353 2.5 9.49997 2.5Z"
                                    fill="#9DA1C3"
                                  />
                                </svg>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GameHistory;
