import { useEffect, useState } from 'react';

export function useIsTelegramWebApp() {
    const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

    useEffect(() => {
        // Check if window.Telegram.WebApp exists
        try {
            console.log("window?.Telegram", window?.Telegram)
            if (window?.Telegram && window?.Telegram?.WebApp) {
                setIsTelegramWebApp(true);
                window?.Telegram?.WebApp?.init(); // Initialize the WebApp if inside Telegram
            }

        } catch (err) {
            console.log("err telegram web", err)
        }
    }, []);

    return isTelegramWebApp;
}
