import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import styles from "./GameHistoryDetailPop.module.scss";
import { decrypt } from "../../../helper/secretManager";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { gameSlice } from "../../../lib/store/game/slice";


import hexActive from "../../../assets/images/hexActive.svg";
import hexWrong from "../../../assets/images/hexWrong.svg";
import hexRight from "../../../assets/images/hexRight.svg";
import hexDef from "../../../assets/images/hexDef.svg";
import rightHex from "../../../assets/images/rightHex.svg";
import leftHex from "../../../assets/images/leftHex.svg";
import hexfade from "../../../assets/images/hexFade.svg";


const GameHistoryDetailPop = ({ show, onHide }) => {
  const { gameHistoryDetails } = useSelector(({ Game }) => Game);
  const [game, SetGame] = useState();
  const [height, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const elementRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(gameSlice.actions.setGameHistoryDetails({ data: null }));
      setTimeout(() => {
        SetGame(null);
      }, 200);
    };
  }, []);

  useEffect(() => {
    if (game && elementRef.current) {
      setHeight(elementRef.current.clientHeight);
    }
  }, [game]);

  console.log("height", height);

  const decordData = async (rowData) => {
    try {
      setIsLoading(true);
      let updatedData = { ...rowData, gameData: [] };

      let decoded = rowData?.gameData ? await decrypt(rowData?.gameData) : [];
      decoded = decoded ? JSON.parse(decoded) : [];
      updatedData = {
        ...updatedData,
        gameData: decoded.reverse(),
      };
      SetGame(updatedData);
    } catch (err) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (gameHistoryDetails?._id) {
      decordData(gameHistoryDetails);
    }
  }, [gameHistoryDetails]);

  console.log("gamegame", game);

  const onCloseHandler = () => {
    onHide();
    console.log("onHide*********");
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => onCloseHandler()}
        backdrop="true"
        centered
        scrollable="true"
        className={`${styles.gameHistoryDetail} bottomPop`}
        keyboard={false}
      >
        <div className="modalWrpper">

          <Modal.Body>
            <Button
              onClick={() => onCloseHandler()}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner className={`${styles.mSpinner}`} size="sm" />
              </div>
            )}
            {game && (
              <div className="mx-auto pt-4" style={{ maxWidth: 292 }}>
                <div
                  className={`${styles.topWrpper} top p-2 px-3 mb-1`}
                // style={{ background: "#0C0C0C" }}
                >
                  <div className="d-flex align-items-center gap-10">
                    <div
                      className="p-1 px-2 rounded text-white fw-sbold"
                      style={{ background: "#0E0E11" }}
                    >
                      ID:{game?.roundId}
                    </div>
                    <div
                      className="p-1 rounded d-flex align-items-center gap-10 themeClr"
                      style={{ background: "#0E0E11" }}
                    >
                      <span>
                        {" "}
                        {game?.completedAt &&
                          moment(game?.completedAt).format("DD.MM.YY")}
                      </span>{" "}
                      <span className="">
                        {game?.completedAt &&
                          moment(game?.completedAt).format("HH:mm:ss")}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center justify-content-between gap-10">
                      <li className="">
                        <p className="m-0 text-white">Claim</p>
                        <div className="d-flex align-items-center gap-10 flex-wrap">
                          {game?.winnings?.length
                            ? game?.winnings?.map((win, key) => (
                              <div
                                key={key}
                                className="p-1 rounded text-white px-2"
                                style={{ background: "#0E0E11" }}
                              >
                                <img
                                  src={win?.icon}
                                  style={{ height: 14 }}
                                  alt=""
                                  className="img-fluid object-fit-contain flex-shrink-0 me-1"
                                />
                                <span className="themeClr fw-sbold">
                                  {win?.amount}
                                </span>
                              </div>
                            ))
                            : null}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bottom mt-2">
                  <h6 className="m-0 text-white ps-3">Result:</h6>
                  <div
                    className={`${styles.historyList} historyList p-2 py-4 px-3 mt-1`}
                  // style={{ background: "#19B0EB" }}
                  >
                    <div className="d-flex align-items-center gap-10">
                      <ul
                        className={`${styles.countList} d-none list-unstyled ps-0 mb-0 position-relative`}
                      >
                        {game?.gameData &&
                          game?.gameData.length > 0 &&
                          game?.gameData.map((item, key) => (
                            <li
                              key={key}
                              className={`${styles.level} ${(!item?.isClicked &&
                                game?._id &&
                                game?.gameData &&
                                game?.gameData?.length -
                                1 -
                                game?.lineCount) === key && styles.active
                                } d-flex align-items-center`}
                              style={{ height: height / 1.15, height: 64, gap: 8 }}
                            >
                              <span
                                className=" fw-bold position-relative"
                                style={{ width: 20 }}
                              >
                                {game?.gameData?.length - key}
                              </span>
                              <span className="icn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="4"
                                  height="4"
                                  viewBox="0 0 4 4"
                                  fill="none"
                                >
                                  <rect
                                    x="0.000244141"
                                    width="4"
                                    height="4"
                                    rx="2"
                                    fill="#506380"
                                  />
                                </svg>
                              </span>
                            </li>
                          ))}
                      </ul>
                      <ul
                        className={`${styles.gameOptionWrp} list-unstyled ps-0 mb-0`}
                      >
                        {game?.gameData &&
                          game?.gameData.length > 0 &&
                          game?.gameData?.map((item, index1) => (
                            <li
                              className="position-relative d-flex align-items-center justify-content-center"
                              ref={elementRef}
                            >
                              <div className={`${styles.listCount} position-absolute text-center d-flex align-items-center`}>  <span
                                className=" fw-bold position-relative"
                                style={{ width: 20 }}
                              >{game?.gameData?.length - index1}</span>  <span className="icn ms-">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="4"
                                    height="4"
                                    viewBox="0 0 4 4"
                                    fill="none"
                                  >
                                    <rect
                                      x="0.000244141"
                                      width="4"
                                      height="4"
                                      rx="2"
                                      fill="#506380"
                                    />
                                  </svg>
                                </span> </div>
                              {index1 % 2 == 0 && (
                                <div
                                  className={`${styles.option}
                                    `}
                                >
                                  <img
                                    src={leftHex}
                                    alt=""
                                    className="img-fluid w-100"
                                  />
                                </div>
                              )}
                              {item?.row &&
                                item?.row.map((data, index2) => {
                                  return (
                                    <div
                                      key={index2}
                                      className={`${styles.option} ${(!item?.isClicked &&
                                        game?._id &&
                                        game?.gameData &&
                                        game?.gameData?.length -
                                        1 -
                                        game?.lineCount) === index1 &&
                                        styles.active
                                        } ${data?.isOpened &&
                                          data?.valueType === "bomb"
                                          ? styles.wrongAns
                                          : null
                                        } ${data?.isOpened &&
                                          data?.valueType !== "bomb"
                                          ? styles.rightAns
                                          : ""
                                        } position-relative d-flex align-items-center flex-wrap justify-content-center`}

                                    >
                                      <span
                                        className={`${styles.hexIcn} hexIcn position-absolute`}
                                      >
                                        {data?.isOpened &&
                                          data?.valueType !== "bomb" ? (
                                          <img
                                            src={hexRight}
                                            alt=""
                                            className="img-fluid w-100"
                                          />
                                        ) : data?.isOpened &&
                                          data?.valueType === "bomb" ? (
                                          <img
                                            src={hexWrong}
                                            alt=""
                                            className="img-fluid w-100"
                                          />
                                        ) : (
                                          <img
                                            src={item?.isClicked && game?.gameData?.length - 1 < game?.lineCount <= index1 ? hexfade : hexDef}
                                            alt=""
                                            className="img-fluid w-100"
                                          />
                                        )}
                                      </span>


                                      <>
                                        {typeof data?.value === "number" &&
                                          !isNaN(data?.value) && (
                                            <span
                                              className={`${styles.value} text-white text-center d-block w-100`}
                                            // style={{ right: 2, bottom: 2 }}
                                            >
                                              {data?.value?.toString().length >
                                                4
                                                ? data?.value.toFixed(2)
                                                : data?.value}
                                            </span>
                                          )}
                                      </>

                                      <>
                                        {data?.valueType === "bomb" && (
                                          <img
                                            src={data?.coinIcon}
                                            alt=""
                                            className="img-fluid object-fit-contain"
                                            style={{ height: "70%" }}
                                          />
                                        )}
                                        {data?.valueType === "BTNC" && (
                                          <img
                                            src={data?.coinIcon}
                                            alt=""
                                            className="img-fluid object-fit-contain"
                                            style={{ height: "50%" }}
                                          />
                                        )}
                                        {data?.valueType === "coin" && (
                                          <img
                                            src={data?.coinIcon}
                                            alt=""
                                            className="img-fluid object-fit-contain"
                                            style={{ height: "50%" }}
                                          />
                                        )}
                                      </>

                                    </div>
                                  );
                                })}
                              {index1 % 2 !== 0 && (
                                <div
                                  className={`${styles.option}
                                    `}
                                >
                                  <img
                                    src={rightHex}
                                    alt=""
                                    className="img-fluid w-100"
                                  />
                                </div>
                              )}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default GameHistoryDetailPop;

