import { Providers } from "./lib/providers";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { privateRoutes, routes } from "./pages";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeGameDetail, getCsrfToken, getSettings, telegramBotAutoLogin, userSession } from "./lib/store/actions";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import MainLayout from "./components/Layout/Mainlayout";
import { useNavigate } from "react-router-dom";
import { ToastProvider } from "./context/CustomToast";
import { preloadSounds, wait } from "./helper/common";
import LoaderCstm from "./components/Loader";
import AuthLayout from "./components/Layout/Authlayout";
import GameEnterRoute from "./components/GameEnterRoute";
import { useIsTelegramWebApp } from "./hooks/useTelegram";
import { GameProvider, useGameContext } from "./context/GameContext";
import { decrypt } from "./helper/secretManager";



function App() {

  return (
    <ToastProvider>
      <Providers>
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          closeButton={false}
        />
        <GameProvider >
          <BrowserRouter>
            <RoutesList />
          </BrowserRouter>
        </GameProvider>
      </Providers>
    </ToastProvider >
  );
}

const RoutesList = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(({ Auth }) => ({
    user: Auth.user
  }));
  const [sessionLoader, setSessionLoader] = useState(true);
  const [loginLoader, setLoginLoader] = useState(true);
  const { SetGame, setGameStart, setGameLoading, gameLoading } = useGameContext();

  const isTelegramWebApp = useIsTelegramWebApp();
  const userId = user?._id;

  useEffect(() => {
    if (userId) {
      dispatch(getSettings({}));
    }
  }, [userId]);

  useEffect(() => {

    try {
      if (isTelegramWebApp && window.Telegram.WebApp.initDataUnsafe) {      // Get the user data from Telegram WebApp
        if (window.Telegram.WebApp.initDataUnsafe.user) {
          const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
          console.log("telegramUser", telegramUser)
          setLoginLoader(true);
          dispatch(telegramBotAutoLogin({
            data: { telegram_id: telegramUser?.id }, callBack: (err, res) => {
              if (err) {
                setLoginLoader(false)
                return;
              }
              navigate("/game");
              setLoginLoader(false)
            }
          }))
        }

      }
    } catch (err) {
      console.log("error", err);
      setLoginLoader(false);
    } finally {
      setLoginLoader(false);
    }

  }, [isTelegramWebApp]);




  useEffect(() => {

    const checkAuth = async () => {
      try {
        setSessionLoader(true);
        await dispatch(userSession({
          callBack: (err, userData) => {
            if (err) {
              setSessionLoader(false);
              document.cookie = "";
              setTimeout(() => {
                navigate("/login");
              }, 1000)

            } else {
              ativeGameDetail(userData);
              setSessionLoader(false);
            }
          }
        })); // Dispatch the action to check authentication
      } catch (error) {
        setSessionLoader(false);
        setGameLoading(false);
        console.error("Error checking auth:", error);
      } finally {
        setSessionLoader(false);
        // setGameLoading(false);
      }
    };
    if (!userId) {
      setTimeout(() => {
        checkAuth();
      }, 300);
    }

  }, [userId]);



  useEffect(() => {

    document.addEventListener('touchmove', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });

  }, []);

  const ativeGameDetail = (userData) => {
    try {
      console.log("userData", userData)
      if (userData?._id && !userData?.currentGame) {
        setTimeout(() => {
          setGameLoading(false);
        }, 5000)
        return;
      }
      let bodyParms = {
        _id: userData?.currentGame,
      };
      setGameLoading(true);
      dispatch(
        activeGameDetail({
          data: bodyParms,
          callBack: async (err, res) => {
            if (err) return setGameLoading(false);
            if (res.data?._id) {


              let updatedData = { ...res.data, gameData: [] };

              let decoded = updatedData?.gameData
                ? await decrypt(res.data?.gameData)
                : [];
              decoded = decoded ? JSON.parse(decoded) : [];
              updatedData = {
                ...updatedData,
                gameData: decoded,
                winnings: updatedData?.winnings?.length
                  ? [...updatedData.winnings]
                  : [],
              };
              SetGame({
                ...updatedData,
                gameData: updatedData?.gameData.reverse(),
              });

              setGameStart(true);
              setTimeout(() => {
                setGameLoading(false);
              }, 1000)
            }
          },
        })
      );
    } catch (err) {
      setGameLoading(false);
    }
  };



  return (
    <Routes>
      <Route element={""}>
        <Route index element={<Navigate to="/login" />} />
        {routes.map((data, index) => (
          <Route
            // onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={
              <GameEnterRoute
                sessionLoader={sessionLoader}
                loginLoader={loginLoader}
                gameLoader={gameLoading}
              >
                {data.component}
              </GameEnterRoute>
            }
            key={index}
          />
        ))}
      </Route>
      <Route element={<MainLayout />}>
        <Route index element={<Navigate to="/game" />} />
        {privateRoutes.map((data, index) => (
          <Route
            // onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element=
            {<PrivateRoute
              sessionLoader={sessionLoader}
              loginLoader={loginLoader}
              gameLoader={gameLoading}
            >
              {data.component}
            </PrivateRoute>}
            key={index}
          />
        ))}
      </Route>
    </Routes>
  )
}
export default App;
