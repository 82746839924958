import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// css
import styles from "./Authe.module.scss";
import { Link, useNavigate } from "react-router-dom";
// import { useCookies } from 'react-cookie';
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { generateTelLoginQrApi, telegramLogin } from "../../../lib/store/auth/actions";
// import { LoginButton } from '@telegram-auth/react';
import qr from "../../../assets/images/qrcode.png"
import { get } from "../../../helper/api_helper";

const Login = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user, generateQrLoading } = useSelector(({ Auth }) => Auth);
  const dispatch = useDispatch();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [qrImage, setQrImage] = useState(null)


  useEffect(() => {
    // Get the hash part of the URL
    const hash = window.location.hash;

    // Extract the tgAuthResult if it's present in the hash
    const match = hash.match(/tgAuthResult=([^&]*)/);
    console.log("match", match);
    if (match && match[1]) {
      // URL decode the value (optional, depending on the encoding)
      const token = decodeURIComponent(match[1]);

      const payload = {
        token: token,
      };
      if (payload.token) {
        dispatch(telegramLogin({
          data: payload, callBack: (err, data) => {
            if (err) return;
            if (data) {
              navigate("/game");
            }
          }
        }))
      }

    }

  }, []);

  const getQrCode = async () => {
    try {
      dispatch(generateTelLoginQrApi({
        callBack: (err, data) => {
          if (err) return;
          setQrImage(data);
        }
      }));
    } catch (err) {

    }
  }

  useEffect(() => {
    getQrCode();
  }, []);


  return (
    <>
      <section
        className={`${styles.authentication} authentication text-center py-5 position-relative d-flex align-items-center justify-content-center`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="5" md="6" sm="10">
              <div className={`${styles.authFormWrpper} p-4`}>
                <div className="formBody">
                  <div className="icon position-relative mx-auto mb-3 bg-white rounded d-flex align-items-center justify-content-center"
                    style={{ height: 150, width: 150 }}>
                    {qrImage ?
                      <>
                        <img src={qrImage} alt="" className="img-fluid w-100 h-100 rounded" />
                        <span className="icn mx-auto position-absolute"
                          style={{ top: "50%", left: 0, right: 0, transform: "translateY(-50%)" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 256 256" fill="none"><g clip-path="url(#clip0_39_650)"><path d="M128 0C94.06 0 61.48 13.494 37.5 37.49C13.4954 61.4956 0.00686897 94.0517 0 128C0 161.934 13.5 194.514 37.5 218.51C61.48 242.506 94.06 256 128 256C161.94 256 194.52 242.506 218.5 218.51C242.5 194.514 256 161.934 256 128C256 94.066 242.5 61.486 218.5 37.49C194.52 13.494 161.94 0 128 0Z" fill="url(#paint0_linear_39_650)"></path><path d="M57.94 126.648C95.26 110.392 120.14 99.6746 132.58 94.496C168.14 79.71 175.52 77.142 180.34 77.055C181.4 77.038 183.76 77.3 185.3 78.545C186.58 79.595 186.94 81.015 187.12 82.012C187.28 83.008 187.5 85.278 187.32 87.05C185.4 107.29 177.06 156.406 172.82 179.076C171.04 188.668 167.5 191.884 164.08 192.198C156.64 192.882 151 187.286 143.8 182.568C132.54 175.182 126.18 170.586 115.24 163.38C102.6 155.052 110.8 150.474 118 142.994C119.88 141.036 152.64 111.246 153.26 108.544C153.34 108.206 153.42 106.946 152.66 106.282C151.92 105.616 150.82 105.844 150.02 106.024C148.88 106.28 130.9 118.176 96.02 141.71C90.92 145.218 86.3 146.928 82.14 146.838C77.58 146.74 68.78 144.254 62.24 142.13C54.24 139.524 47.86 138.146 48.42 133.72C48.7 131.416 51.88 129.058 57.94 126.648Z" fill="white"></path></g><defs><linearGradient id="paint0_linear_39_650" x1="12800" y1="0" x2="12800" y2="25600" gradientUnits="userSpaceOnUse"><stop stop-color="#2AABEE"></stop><stop offset="1" stop-color="#229ED9"></stop></linearGradient><clipPath id="clip0_39_650"><rect width="256" height="256" fill="white"></rect></clipPath></defs></svg>
                        </span>
                      </>
                      :
                      <p className="m-0">Loading...</p>
                    }
                  </div>

                  {/* <h4 className="m-0 text-white py-1">Login With Telegram</h4> */}
                  <p className="m-0 text-white py-1"></p>
                  {/* <Button
                    onClick={() => navigate("/game")}
                    className="d-flex w-100 align-items-center justify-content-center commonBtn mt-2"
                  >
                    Login
                  </Button> */}
                  <>
                    {/* <h1>Welcome, {telegramUser?.first_name}!</h1>
                    <p>Telegram ID: {telegramUser?.id}</p> */}
                    {/* <h1>Hello, anonymous!</h1> */}
                    {/* <div id="telegram-login"></div> */}
                    {/* <LoginButton
                        botUsername={telegramBotUsername}
                        authCallbackUrl={authCallbackUrl}
                        buttonSize="large" // "large" | "medium" | "small"
                        cornerRadius={5} // 0 - 20
                        showAvatar={true} // true | false
                        lang="en"
                      /> */}
                    {/* <Button
                    onClick={() => navigate("/game")}
                    className="d-flex w-100 align-items-center justify-content-center commonBtn mt-2"
                  >
                    Login
                  </Button> */}
                    <div className="content">
                      <h6 className="m-0 fw-sbold text-center text-white">Log in to Telegram by QR Code</h6>
                      <ul className="list-unstyled ps-0 mb-0 text-start" style={{ fontSize: 14 }}>
                        <li className="py-1 d-flex align-items-start text-white gap-10">
                          <span className="icn d-flex text-white align-items-center flex-shrink-0 justify-content-center rounded-pill"
                            style={{ height: 20, width: 20, fontSize: 10, background: "#2aabed" }}>1</span>
                          Open Telegram on your phone
                        </li>
                        <li className="py-1 d-flex align-items-start text-white gap-10">
                          <span className="icn d-flex text-white align-items-center flex-shrink-0 justify-content-center rounded-pill"
                            style={{ height: 20, width: 20, fontSize: 10, background: "#2aabed" }}>2</span>
                          Go to Settings > Devices > Link Desktop Device
                        </li>

                        <li className="py-1 d-flex align-items-start text-white gap-10">
                          <span className="icn d-flex text-white align-items-center flex-shrink-0 justify-content-center rounded-pill"
                            style={{ height: 20, width: 20, fontSize: 10, background: "#2aabed" }}>3</span>
                          Point your phone at this screen to confirm login
                        </li>
                      </ul>
                    </div>
                    <div className="">

                      <Button
                        style={{ maxWidth: 250 }}
                        className={`${styles.telegramBtn} mx-auto rounded-pill w-100 d-flex px-4 align-items-center justify-content-center  mt-2`} onClick={() =>
                          window.open(`https://oauth.telegram.org/auth?bot_id=7485127762&origin=https%3A%2F%2Fwww.bitanicagames.com&embed=1&return_to=https%3A%2F%2Fwww.bitanicagames.com%2Flogin/?v=${Date.now()}`, "_self")
                        }> <span className="icn me-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.777 4.43C20.0241 4.32599 20.2946 4.29012 20.5603 4.32612C20.826 4.36212 21.0771 4.46867 21.2877 4.63469C21.4982 4.80071 21.6604 5.02012 21.7574 5.27009C21.8543 5.52005 21.8825 5.79144 21.839 6.056L19.571 19.813C19.351 21.14 17.895 21.901 16.678 21.24C15.66 20.687 14.148 19.835 12.788 18.946C12.108 18.501 10.025 17.076 10.281 16.062C10.501 15.195 14.001 11.937 16.001 10C16.786 9.239 16.428 8.8 15.501 9.5C13.199 11.238 9.50299 13.881 8.28099 14.625C7.20299 15.281 6.64099 15.393 5.96899 15.281C4.74299 15.077 3.60599 14.761 2.67799 14.376C1.42399 13.856 1.48499 12.132 2.67699 11.63L19.777 4.43Z" fill="#fff" />
                        </svg></span> Login With Telegram</Button>


                      {/* <Button
                        style={{ maxWidth: 250 }}
                        className={`${styles.telegramBtn} mx-auto rounded-pill w-100 d-flex px-4 align-items-center justify-content-center  mt-2`} >
                        <span className="icn me-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_44_4619)">
                              <path d="M2.25 2.25H8.25V0.75H0.75V8.25H2.25V2.25Z" fill="white" />
                              <path d="M8.25 8.25V3.75H3.75V8.25H8.25ZM5.25 5.25H6.75V6.75H5.25V5.25ZM15.375 2.25H21.75V8.25H23.25V0.75H15.375V2.25Z" fill="white" />
                              <path d="M20.25 8.25V3.75H15.75V8.25H20.25ZM17.25 5.25H18.75V6.75H17.25V5.25ZM8.25 21.75H2.25V15.75H0.75V23.25H8.25V21.75Z" fill="white" />
                              <path d="M8.25 15.75H3.75V20.25H8.25V15.75ZM6.75 18.75H5.25V17.25H6.75V18.75ZM21.75 21.75H15.375V23.25H23.25V15.75H21.75V21.75Z" fill="white" />
                              <path d="M12.75 14.25H18.75V17.25H20.25V12.75H12.75V14.25Z" fill="white" />
                              <path d="M20.25 20.25V18.75H11.25V12.75H3.75V14.25H9.75V20.25H20.25ZM9.75 3.75H11.25V8.25H9.75V3.75Z" fill="white" />
                              <path d="M3.75 11.25H14.25V3.75H12.75V9.75H3.75V11.25ZM15.75 9.75H20.25V11.25H15.75V9.75ZM15.75 15.75H17.25V17.25H15.75V15.75ZM12.75 15.75H14.25V17.25H12.75V15.75Z" fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_44_4619">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg></span>   Login With QR
                      </Button> */}
                    </div>

                  </>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section >
    </>
  );
};

export default Login;
