import React, { useEffect, useState } from "react";
import styles from "./roadmap.module.scss";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import falseIcn from "../../../assets/images/falseIcn.svg";
import trueIcn from "../../../assets/images/trueIcn.svg";
import { useDispatch, useSelector } from "react-redux";
import { getRoadmapsListApi } from "../../../lib/store/actions";
import LoaderCstm from "../../../components/Loader";
const Roadmap = () => {
  const dispatch = useDispatch();
  const { loading, roadMapsList, totalRoadMaps } = useSelector(({ Tasks, Auth }) => ({
    user: Auth.user,
    loading: Tasks.loading,
    roadMapsList: Tasks.roadMapsList,
    totalRoadMaps: Tasks.totalRoadMaps
  }));
  const [filter, setFilter] = useState({
    page: 1,
    limit: 50,
    order: -1,
    orderBy: "createdAt",
    search: "",
    status: "active",
  });

  useEffect(() => {
    dispatch(getRoadmapsListApi({ data: filter }));
  }, [filter]);


  const data = [
    {
      quarter: "Q4 2024",
      title: " Game Development /Community Building",
      subPoints: [
        {
          status: "completed",
          point: "Launch Game  (Telegram / Web versions               ",
        },
        {
          status: "pending",
          point: "Introduce Reward Boxes               ",
        },
        {
          status: "completed",
          point: "Task Completion System              ",
        },
        {
          status: "completed",
          point: "Leaderboard Implementation",
        },
        {
          status: "pending",
          point: "Community Building Initivaties",
        },
        {
          status: "completed",
          point: "Integration With Bitanica Platform",
        },
      ],
    },
    {
      quarter: "Q1 2025",
      title: " Community Building / Partnerships",
      subPoints: [
        {
          status: "completed",
          point: "Launch Game  (Telegram / Web versions               ",
        },
        {
          status: "pending",
          point: "Introduce Reward Boxes               ",
        },
        {
          status: "completed",
          point: "Task Completion System              ",
        },
        {
          status: "completed",
          point: "Leaderboard Implementation",
        },
        {
          status: "pending",
          point: "Community Building Initivaties",
        },
        {
          status: "completed",
          point: "Integration With Bitanica Platform",
        },
      ],
    },
  ];
  return (
    <>
      <section className={`${styles.roadmap} roadmap position-relataive`}>
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="top text-center">
                <h5 className="m-0 themeClr fw-bold">Roadmap</h5>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              {loading && filter.page === 1 && (
                <LoaderCstm />
              )}
              {!loading && !roadMapsList?.length ?
                <div className="d-flex justify-content-center align-items-center">
                  <span>No record found!</span>
                </div>
                : null}
              <Accordion defaultActiveKey="0" className={`${styles.Accordion}`}>
                {roadMapsList?.map((item, key) => {
                  return (
                    <Accordion.Item
                      className={`${styles.accordionItem} my-3`}
                      eventKey={key}
                      key={key}
                    >
                      <Accordion.Header className={`${styles.Header}`}>
                        <div className="">
                          <span className="themeClr fw-bold">
                            {item.quarter} /
                          </span>{" "}
                          {item.title}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className={`${styles.body}`}>
                        <ul className="list-unstyled ps-0 mb-0">
                          {item.subPoints.map((data, index) => (
                            <li className="py-1 d-flex align-items-start gap-10">
                              <span className="icn flex-shrink-0">
                                {data.status === "completed" ? (
                                  <img
                                    src={trueIcn}
                                    alt=""
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={falseIcn}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </span>
                              {data.point}
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Roadmap;
