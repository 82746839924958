import React from "react";
import Footer from "../../Footer/footer";
import { Outlet } from "react-router-dom";

// css
import styles from "./mainlayout.module.scss";
import Header from "../../Header";

const MainLayout = () => {
    return (
        <>
            <main>
                <Header />
                <Outlet />
                <Footer />
            </main>
        </>
    );
};

export default MainLayout;
