import React from 'react'
import styles from "./splash.module.scss"

import logo from "../../assets/images/diamond.png"

const SplashScreen = () => {
    return (
        <>
            <div className={`${styles.splashScreen} d-flex align-items-center justify-content-center w-100 position-fixed`} style={{ top: 0, right: 0, zIndex: 99999, height: "100vh", background: "#000" }}>
                <div className="text-center">
                    <img src={logo} alt="" className="img-fluid object-fit-contain" style={{ height: 65 }} />
                    <p className="m-0 fw-bold text-center text-white">Mine Hunt</p>
                </div>
            </div>
        </>
    )
}

export default SplashScreen