import React from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
// img
import h1 from "../../../assets/images/hgame.png";
import h2 from "../../../assets/images/hleaderboard.png";

const HowToPlayPop = ({ playInst, setPlayInst }) => {
  const handlePlayInst = () => {
    setPlayInst(!playInst);
  };
  return (
    <>
      <Modal
        show={playInst}
        onHide={handlePlayInst}
        backdrop="true"
        centered
        className="bottomPop"
        keyboard={false}
        scrollable="true"
      >
        <div className="modalWrpper">

          <Modal.Body>
            <Button
              onClick={handlePlayInst}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            <div className="top pb-3 text-center">
              <h5
                className="m-0 text-white d-inline-block  pb-1 px-3"
                style={{ borderBottom: "1px solid #02ADEA" }}
              >
                How to play
              </h5>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Game">
              <Nav
                variant="pills"
                className="justify-content-center inline-flex rounded-4 mx-auto p-1 PillsTab"
                style={{
                  background: "#13131b",
                  border: "1px solid #13131b",
                  maxWidth: "max-content",
                }}
              >
                <Nav.Item>
                  <Nav.Link
                    className="d-flex align-items-center justify-content-center p-1 fw-bold"
                    eventKey="Game"
                  >
                    Game
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="d-flex align-items-center justify-content-center p-1 fw-bold"
                    eventKey="Leaderboard"
                  >
                    Leaderboard
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content
                className="pt-3"
                style={{ fontSize: 14, lineHeight: "20px" }}
              >
                <Tab.Pane eventKey="Game">
                  <div className="py-2">
                    <ul className="text-white mb-0">
                      <li className="">Clicking "Start" reveals four blue squares.</li>
                      <li className="">Select one to uncover either a BTNC or a Bomb.</li>
                      <li className="">Finding a BTNC allows you to continue, while finding a Bomb ends the round.</li>
                      <li className="">If you hit a bomb, all the BTNC you've collected will remain in your balance.</li>
                      <li className="">Clicking "Stop" will end the round.</li>
                      <li className="">If you run out of ENERGY, you can purchase more using BTNC.</li>
                    </ul>
                  </div>
                  <div className="py-2 text-center">
                    <img src={h1} alt="" className="img-fluid" />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Leaderboard">
                  <div className="py-2">
                    <ul className="text-white mb-0">
                      <li className="">The leaderboard scoring system is simple: advancing to line X awards you X points.
                      </li>
                      <li className="">For instance, reaching line 5 gives you 5 points, and reaching line 7 gives you 7 points. </li>
                      <li className="">If you successfully open 2 squares but hit a bomb on the 3rd, you’ll earn 2 points. </li>
                      <li className="">You can check the leaderboard duration in the top left corner of the section.</li>
                    </ul>
                  </div>
                  <div className="py-2 text-center">
                    <img src={h2} alt="" className="img-fluid" />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default HowToPlayPop;
